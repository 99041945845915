import { match as benefitPeriod } from "../../../src/params/benefitPeriod.ts";
import { match as benefitType } from "../../../src/params/benefitType.ts";
import { match as code } from "../../../src/params/code.ts";
import { match as messageType } from "../../../src/params/messageType.ts";
import { match as number } from "../../../src/params/number.ts";
import { match as objectid } from "../../../src/params/objectid.ts";
import { match as presentationKey } from "../../../src/params/presentationKey.ts";
import { match as presentationSection } from "../../../src/params/presentationSection.ts";
import { match as resourceType } from "../../../src/params/resourceType.ts";
import { match as sectionKey } from "../../../src/params/sectionKey.ts";
import { match as videoSection } from "../../../src/params/videoSection.ts";

export const matchers = { benefitPeriod, benefitType, code, messageType, number, objectid, presentationKey, presentationSection, resourceType, sectionKey, videoSection };