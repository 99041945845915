import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66')
];

export const server_loads = [3,4,6,7,5,15,9,10,12,14,13];

export const dictionary = {
		"/": [~17],
		"/(backend)/admin": [~24,[3,4]],
		"/(backend)/admin/brokers": [~25,[3,4]],
		"/(backend)/admin/brokers/create": [~27,[3,4]],
		"/(backend)/admin/brokers/[id=objectid]": [~26,[3,4]],
		"/(backend)/admin/clients": [~28,[3,4]],
		"/(backend)/admin/users": [~29,[3,4]],
		"/(backend)/admin/users/[id]": [~30,[3,4]],
		"/(backend)/broker": [~31,[3]],
		"/(backend)/broker/benefits/[benefitId=objectid]/specs": [~35,[3,6]],
		"/(backend)/broker/benefits/[benefitId=objectid]/[planId=objectid]/[[section=number]]": [~34,[3,6]],
		"/(backend)/broker/clients": [~36,[3]],
		"/(backend)/broker/clients/new": [~45,[3]],
		"/(backend)/broker/clients/[clientId=objectid]": [~37,[3]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/benefits": [~38,[3,7]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/benefits/[period=benefitPeriod]": [39,[3,7]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/benefits/[period=benefitPeriod]/specs": [~40,[3,7]],
		"/(backend)/broker/clients/[clientId=objectid]/edit": [~44,[3]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/resources": [~41,[3,7]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/resources/[resourceType=resourceType]": [~42,[3,7]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/resources/[resourceType]": [~43,[3,7]],
		"/(backend)/broker/(profile)/contacts": [32,[3,5]],
		"/(backend)/broker/delivery": [~46,[3]],
		"/(backend)/broker/events": [~47,[3]],
		"/(backend)/broker/messages": [~48,[3]],
		"/(backend)/broker/messages/create": [~50,[3]],
		"/(backend)/broker/messages/[id]": [~49,[3]],
		"/(backend)/broker/(profile)/profile": [33,[3,5]],
		"/(backend)/broker/vendors": [~51,[3]],
		"/(backend)/broker/vendors/new": [~53,[3]],
		"/(backend)/broker/vendors/[id=objectid]": [~52,[3]],
		"/builder/[slug]/[period=benefitPeriod]": [~65,[15]],
		"/dev/presentation-test": [66,[16]],
		"/(auth)/forgot-password": [~18,[2]],
		"/(auth)/login": [~19,[2]],
		"/(auth)/logout": [~20,[2]],
		"/(backend)/(terms)/privacy": [~22,[3]],
		"/(auth)/reset-password/[token]": [~21,[2]],
		"/(backend)/(terms)/terms": [~23,[3]],
		"/(backend)/user": [~54,[3]],
		"/(participants)/[[lang]]/v/[slug]": [~55,[8,9,10]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/authorize": [~63,[8,9,10,11]],
		"/(participants)/[[lang]]/v/[slug]/[benefitId=objectid]": [~64,[8,9,10]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]": [~56,[8,9,10,11,12]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]/welcome": [58,[8,9,10,11,12,14]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]/welcome/contacts": [~60,[8,9,10,11,12,14]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]/welcome/documents": [~61,[8,9,10,11,12,14]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]/welcome/enrollment": [~62,[8,9,10,11,12,14]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]/welcome/[videoId=objectid]": [~59,[8,9,10,11,12,14]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]/[sectionKey=sectionKey]/[[playlistKey]]": [~57,[8,9,10,11,12,13]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';